import React from 'react';
import * as Sentry from "@sentry/react";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

import './public/assets/css/fa-min.css';
import './public/assets/css/paper-dashboard.css';
import './public/assets/css/custom.css';

import Content from './views';
import { storage } from './utils';

const FallBack = ({ error, componentStack, resetError }) => (
  <>
    <div>You have encountered an error. Please, contact system administrators.</div>
    {/*<div>{error.toString()}</div>*/}
    {/*<div>{componentStack}</div>*/}
  </>
);

const Component = () => (
  <Provider store={storage.store}>
    <PersistGate persistor={storage.persistor} loading={<></>}>
      <Router>
        <Content />
      </Router>
    </PersistGate>
  </Provider>
);

export default Sentry.withErrorBoundary(Component, { fallback: FallBack });
