import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import config from './config';
import connection from './connection';
import language from './language';
// import messenger from './messenger';
import user from './user';
import devices from './devices';
import groups from './groups';
import registration from './registration';
import networks from './networks';

const persistConfig = {
  key: 'app-state',
  storage,
  whitelist: ['config', 'user', 'language', 'groups', 'devices', 'registration'],
};

export default persistReducer(persistConfig, combineReducers({
  config,
  connection,
  language,
  // messenger,
  user,
  devices,
  groups,
  registration,
  networks,
}));
