export default {
  system: {
    error_500: 'System Error. Try again later, or contact System Administrator.',
  },
  auth: {
    login: {
      title: 'Login',
      lbl_username: 'Username',
      lbl_password: 'Password',
      btn_submit: 'Login',
      error_401: 'Invalid credentials.',
      error_403: 'Email address is not verified.',
    },
    code: {
      title: 'Login',
      lbl_code: 'Code',
      btn_submit: 'Verify',
      btn_logout: 'Log out',
      error_401: 'Invalid code.',
      error_403: 'Stop hacking around, please.',
    },
  },

  footer: {
    made_with: 'made with',
    by: 'by Eltrum',
  },
  dashboard_header: {
    change_psw: 'Change password',
    refresh: 'Refresh',
    logout: 'Logout',
  },
  sidebar: {
    devices: 'DEVICES',
    groups: 'GROUPS',
  },

  device_add: {
    title: 'Add device',
    label: 'Do you have "Eltrum" gateway?',
    yes_btn: 'Yes',
    no_btn: 'No',
  },

  device_assign: {
    title: 'Add device',
    label: 'Enter device code',
    input_lbl: 'Device code',
    btn_submit: 'Submit',
    error_403: 'Invalid code specified. Try again, or contact system administrators.'
  },

  // ---Device registration---
  device_reg: {
    title: 'Device registration',

    // inval_stat: 'Device registration could not be initialized.',
    // inval_msg: 'Please, connect to internet before starting device registration.',
    // // ---Networks---
    // conn_ssid: 'Device connected to ',
    // select_eltrum: 'Select \'ELTRUM-DEVICE\' in your WiFi list.',
    // info_msg_1: 'Go to your mobile device settings and switch WiFi from your internet connection to \'ELTRUM-DEVICE\'.',
    // info_msg_2: 'Make sure you are close to device and reaching it\'s WiFi hotspot.',
    // info_msg_3: 'Make sure you are disconnected from internet (mobile data).',

    step1: 'Pair your Device',
    step2: 'Connect Device to WiFi',
    step3: 'Connect Device to Cloud',

    start: {
      info_lbl1: 'Go to your mobile device settings.',
      info_lbl2: 'Select \'ELTRUM-xxxxxx\' in WiFi list.',
      info_lbl3: 'Wait for \'ELTRUM-DEVICE\' to pair with your mobile device.',
    },

    finish: {
      info_lbl1: 'Device connected to WiFi - ',
      info_lbl2: 'Registering device to Eltrum Cloud. Please wait.',
    },

    fatal: {
      info_lbl1: 'Device could not connect to Cloud.',
      info_lbl2: 'Reset your device and restart registration.',
      info_lbl3: 'Contact System Administrators.',
    },

    offline: {
      info_lbl1: 'Connecting to your device. Please wait.',
      info_lbl2: 'Make sure \'ELTRUM-xxxxxx\' is selected in your WiFi list.',
      info_lbl3: 'Make sure you are close enough to \'ELTRUM-DEVICE\'.',
    },

    connected: {
      info_lbl1: 'Device connected to WiFi - ',
      info_lbl2: 'Reconnecting to your internet WiFi.',
      info_lbl3: 'Please wait, or reconnect manually.',
    },

    wifi: {
      info_lbl1: 'Wait for WiFi list to load.',
      info_lbl2: 'Select your WiFi name and enter password.',
      info_lbl3: 'Connecting device to WiFi. Please wait.',
      info_lbl4: 'Could not connect to specified WiFi.',
      info_lbl5: 'Make sure you\'ve provided correct data.',
      info_lbl6: 'Please, try again.',

      ssid_lbl: 'WiFi Network',
      password_lbl: 'WiFi Password',
      connect_btn: 'Connect',

      error_400: 'Could not connect to specified WiFi. Please, try again.',
      error_404: 'Could not connect to specified WiFi. Please, try again.',
      error_500: 'Could not connect to specified WiFi. Please, try again.',

      list_loading: 'Loading WiFi network list. Please wait.',
      select: 'Select WiFi network',
    }
  },

  root: {
    profile: {
      menu: {
        details_lbl: 'Profile',
        credentials_lbl: 'Login',
      },
      profile_ttl: 'User',
      credentials: {
        soc_lbl: 'Using social networks',
        soc_small_lbl: 'Choose and assign your social network profile',
        fb_lbl: 'facebook',
        google_lbl: 'google',
        pwd_lbl: 'Or using password',
        pwd_small_lbl: 'Enter new or change your existing password',
      },
    },
    settings: {
      menu: {
        groups_lbl: 'Groups',
        languages_lbl: 'Language',
        version_lbl: 'Version',
      },
      settings_ttl: 'Settings',
      show_device_groups_lbl: 'Show groups',
      groups_lbl: 'Device groups settings',
      group_field_placeholder: 'Name',
      languages_lbl: 'Change language',
    },
    messenger: {
      messenger_ttl: 'Messenger',
    }
  },

  // ---Device settings---
  settings: {
    menu: {
      group_lbl: 'Group',
      share_lbl: 'Share',
      logs_lbl: 'Logs',
      info_lbl: 'Info',
    },

    group: {
      assign_lbl: 'Assign device to group',
    },

    share: {
      invite_lbl: 'Invite others to use Your device',
      email_lbl: 'E-mail',
      max_lbl: 'shares maximum.',
      contact_admin_lbl: 'Contact System Administrators for more.',

      error_400: 'Invalid e-mail specified.',
      error_401: 'Access denied.',
      error_403: 'Maximum sharing number exceeded. Contact System Administrators for more.',
    },

    logs: {
      logs_lbl: 'Device logs',
      empty_lbl: 'No records found.'
    },

    info: {
      type_lbl: 'Type:',
      mac_lbl: 'MAC:',
      upgrade_warning: 'Your device firmware will be upgraded. ' +
        'All settings in device will be erased and device will be restarted. ' +
        'Operation will take few minutes. ' +
        'Do you really want to upgrade your device firmware?',
      remove_warning: 'All stored device data will be erased. ' +
        'All device settings will be erased. ' +
        'Device will be restored to factory defaults. ' +
        'Device will be removed and restarted. ' +
        'Do you really want to remove your device?',
    },

    settings: {
      upgrade_btn: 'Upgrade',
      remove_btn: 'Remove',
      yes_btn: 'Yes',
      no_btn: 'No',
    },

    reports: {
      name_lbl: 'Name',
    },
  },

  types: {
    device: 'Device',
    lock: 'Lock',
    thermo: 'Thermometer',
    gates: 'Gates',
    switch: 'Switch',
  },

  devices: {
    device: {
      name_lbl: 'Name',
    },
    gates: {
      manual_lbl: 'manual',
      auto_lbl: 'auto',
      auto_settings_lbl: 'auto settings',
      auto_time_lbl: 'minutes',
    },
    lock: {
      key_owner_name_lbl: 'Key owner name',
    },
    switch: {
      on: 'On',
      off: 'Off',
    },
    thermo: {
      sensor_list_lbl: 'Sensors',
      temp_raw_lbl: 'Raw temperature indicators',
      temp_diff_lbl: 'Temperature difference indicators',
      warning_from_lbl: 'Warning from',
      danger_from_lbl: 'Danger from',
      save_config_btn: 'Save',
      remove_device_btn: 'Remove device',
      upgrade_device_btn: 'Upgrade device',
      yes_btn: 'Yes',
      no_btn: 'No',
      min_lbl: 'min temp.',
      avg_lbl: 'avg temp.',
      max_lbl: 'max temp.',
      diff_lbl: 'max diff.',
    },
    thermo_x4: {
      min_lbl: 'min',
      max_lbl: 'max',
      avg_lbl: 'avg',
    }
  },

  profile: {
    info: {
      title: 'Profile',
    }
  },

  modal: {
    install: {
      title: 'Installation',
      lbl_do_not_show_again: 'Do not show this again',
      btn_close: 'Close',
    }
  },
}

