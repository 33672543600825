import React from 'react';
import { NavLink } from 'react-router-dom';

import { Form } from '../../../../components';

const Component = ({ form, state, handlers }) => (
  <div className="col-md-6">
    <div className="card">
      <div className="card-header border-bottom">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">Add new App version</h5>
          <div>
            <NavLink className="btn btn-round btn-warning" to={`/app`}>
              <i className="nc-icon nc-minimal-left"/>
            </NavLink>
          </div>
        </div>
      </div>
      <Form.Progress on={state.pending} />
      <Form.Messages form={form} state={state} onClose={handlers.onReset} />
      <div className="card-body">
        <form onSubmit={form.handleSubmit(handlers.onSubmit)} onChange={handlers.onReset}>
          <label>Version</label>
          <div className="form-group">
            <input name="version" ref={form.register} maxLength={10} className="form-control" placeholder="Enter version"/>
          </div>
        </form>
      </div>
      <div className="card-footer border-top">
        <Form.Submit form={form} state={state} label="Save" onSubmit={form.handleSubmit(handlers.onSubmit)} />
      </div>
    </div>
  </div>
);

export default Component;
