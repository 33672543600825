import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { pathOr } from 'ramda';
import { joiResolver } from '@hookform/resolvers/joi';

import config from '../../config';
import callApi from '../api/callApi';
import { api as endpoints } from '../api';
import { user as userModule } from '../../modules';

const AUTH_STATE_PATH = ['user'];
const LANG_STATE_PATH = ['language', 'code'];

const STATE_TIMEOUT = 1000;

const usePrevious = (value) => {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const useSubmitted = (result, checkSubmit) => {
  const [submitted, setSubmitted] = useState(false);
  const previous = usePrevious(result);

  useEffect(() => {
    if ((previous === null) && (previous !== result) && checkSubmit) {
      setSubmitted(true);
      setTimeout(() => setSubmitted(false), STATE_TIMEOUT);
    }
  }, [previous, result, checkSubmit]);
  return submitted && !!result;
};

const useApiForm = (api, successCallback, validationSchema = null, defaultValues = {}, checkSubmit = false) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => pathOr({}, AUTH_STATE_PATH)(state));
  const language = useSelector((state) => pathOr(config.LANG, LANG_STATE_PATH)(state));
  const [result, setResult] = useState(null);
  const submitted = useSubmitted(result, checkSubmit);
  const { formState, register, unregister, handleSubmit, errors, setValue, getValues, reset, triggerValidation } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: validationSchema ? joiResolver(validationSchema) : undefined,
    defaultValues,
  });

  const onClearMessage = () => setResult(null);

  const submitAction = useCallback((body) => {
    const successFn = (result) => {
      setResult({ error: false });
      successCallback(result);
    };

    const handleError = (error, api, body) => {
      // token expired
      if (error.status === 451) {
        callApi(endpoints.auth.refreshToken(), null, { jwt: user.refresh }).then((result) => {
          if (result.error) {
            dispatch(userModule.logout.action());
          } else {
            const freshUser = result;
            dispatch(userModule.login.action(freshUser));
            callApi(api, body, freshUser).then((result) => {
              if (result.error) {
                setResult({ ...result, error: true });
              } else {
                successFn(result);
              }
            });
          }
        });
      } else {
        setResult({ ...error, error: true });
      }
    };

    setResult(null);
    callApi(api, body, user, language).then((result) => {
      if (result.error) {
        return handleError(result);
      }
      successFn(result);
    });
  }, [dispatch, api, user, language, successCallback]);

  return {
    onSubmit: handleSubmit(submitAction),
    onClearMessage,
    form: formState,
    register,
    unregister,
    setValue,
    getValues,
    errors,
    result,
    submitted,
    reset,
    triggerValidation,
  };
};

export default useApiForm;
