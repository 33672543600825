import React from 'react';
import { isEmpty } from 'ramda';

const Progress = ({ on = false, success = false, error = false }) => {
  const invisible = !on && !success && !error;
  const classes = `${invisible ? 'progressBarInvisible' : ''} ${error ? 'bg-danger' : ''} ${success ? 'bg-success' : ''}`;
  return (
    <div className={`progressBarRoot ${classes}`}>
      {on && (
        <>
          <div className="progressBar bar1Indeterminate" />
          <div className="progressBar bar2Indeterminate" />
        </>
      )}
    </div>
  );
};

const Messages = ({ form, state, successMessage = 'Success.', onClose }) => (
  <>
    {!isEmpty(form.errors) && (
      <div className="card-body bg-warning text-white pt-2 pb-2 border-bottom">
        {Object.keys(form.errors).map((key, idx) => (
          <div key={idx}>{form.errors[key].message}</div>
        ))}
      </div>
    )}
    {state.error && (
      <div className="card-body bg-danger text-white pt-2 pb-2 border-bottom d-flex">
        <div>
          <div>{state.error.message}</div>
          {state.error.data && state.error.data.message && (
            <div>{state.error.data.message}</div>
          )}
        </div>
        <button type="button" className="close flex-shrink-1 ml-2" onClick={onClose}>
          <i className="nc-icon nc-simple-remove"/>
        </button>
      </div>
    )}
    {state.success && (
      <div className="card-body bg-success text-white pt-2 pb-2 border-bottom d-flex">
        <div className="w-100">{successMessage}</div>
        <button type="button" className="close flex-shrink-1 ml-2" onClick={onClose}>
          <i className="nc-icon nc-simple-remove"/>
        </button>
      </div>
    )}
  </>
);

const Submit = ({ form, state, label = 'Submit', onSubmit }) => (
  <button type="submit" className="btn btn-info float-right" onClick={onSubmit}
          disabled={!isEmpty(form.errors) || !form.formState.isValid || state.pending}
  >
    {label}
  </button>
);

export { Progress, Messages, Submit };
