import React, { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';
import moment from 'moment';

import View from './view';
import { useApiRequest } from '../../../../../utils/hooks';
import { api as endpoints } from '../../../../../utils/api';
import config from '../../../../../config';

const Component = () => {
  const { state, execute } = useApiRequest();
  useEffect(() => execute(endpoints.users.loadList()), [execute]);

  const [ data, setData ] = useState([]);
  useEffect(() => {
    setData((isEmpty(state.data) ? [] : state.data).map(entity => ({
      ...entity,
      owned: entity.owned.length,
      shared: entity.shared.length,
      created: moment(entity.created).format('YYYY-MM-DD HH:mm:ss'),
      lastActivity: entity.lastActivity?.time ? moment(entity.lastActivity.time).format('YYYY-MM-DD HH:mm:ss') : '-',
    })));
  } , [state.data, setData]);

  const [ sort, setSort ] = useState({ name: '', asc: true });
  const [ filters, setFilters ] = useState({ id: '', email: '', owned: '', shared: '' });

  const total = data.length;
  const list = data
    .filter(entity => (
      (!filters.id || entity.id.toLowerCase().includes(filters.id.toLowerCase())) &&
      (!filters.email || entity.email.toLowerCase().includes(filters.email.toLowerCase())) &&
      (!filters.owned || `${entity.owned.length}`.includes(filters.owned)) &&
      (!filters.shared || `${entity.shared.length}`.includes(filters.shared))
    ))
    .sort((a, b) =>
      (sort.asc && (a[sort.name] < b[sort.name])) || (!sort.asc && (a[sort.name] > b[sort.name])) ? -1 : 1
    );

  const { state: loginState, execute: loginExecute } = useApiRequest();
  useEffect(() => {
    if (loginState.data.jwt) {
      window.open(`${config.APP_ROOT}/ext/${loginState.data.jwt}`);
    }
  }, [loginState]);

  const handlers = {
    onSortChange: (fieldName) => () => (sort.name === fieldName)
      ? setSort({ ...sort, asc: !sort.asc })
      : setSort({ name: fieldName, asc: true }),
    onFilterChange: (fieldName) => (e) => setFilters({ ...filters, [fieldName]: e.target.value }),

    onLogin: (userId) => () => {
      loginExecute(endpoints.auth.appLogin(userId));
    },
  };

  return <View list={list} total={total} sort={sort} filters={filters} handlers={handlers} />;
};

export default Component;
