import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import moment from 'moment';

import View from './view';
import { useApiRequest } from '../../../../../utils/hooks';
import { api as endpoints } from '../../../../../utils/api';

const Component = () => {
  const { state: firmware, execute: fwExecute } = useApiRequest();
  useEffect(() => fwExecute(endpoints.firmware.loadList()) , [fwExecute]);
  const versions = !isEmpty(firmware.data)
    ? firmware.data.reduce((obj, entity) => ({ ...obj, [entity.type]: entity.version }), {}) : {};

  const { state, execute } = useApiRequest();
  useEffect(() => execute(endpoints.devices.loadList()) , [execute]);

  const [ data, setData ] = useState([]);
  useEffect(() => {
    setData((isEmpty(state.data) ? [] : state.data).map(entity => ({
      ...entity,
      lastSeen: moment(entity.updated).format('YYYY-MM-DD HH:mm:ss'),
      lastUsed: entity.data.dataDate ? moment(entity.data.dataDate).format('YYYY-MM-DD HH:mm:ss')
        : (entity.data.time ? moment(entity.data.time).format('YYYY-MM-DD HH:mm:ss') : '-'),
      ownerEmail: entity.owner ? entity.owner.email : '-',
      created: moment(entity.created).format('YYYY-MM-DD HH:mm:ss'),
    })));
  } , [state.data, setData]);

  const [ sort, setSort ] = useState({ name: '', asc: true });
  const [ filters, setFilters ] = useState({ id: '', mac: '', type: '', name: '', ownerEmail: '' });

  const list = data
    .filter(entity => (
      (!filters.id || entity.id.toLowerCase().includes(filters.id.toLowerCase())) &&
      (!filters.mac || entity.mac.toLowerCase().includes(filters.mac.toLowerCase())) &&
      (!filters.type || entity.type.toLowerCase().includes(filters.type.toLowerCase())) &&
      (!filters.name || entity.name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (!filters.version || entity.version.toLowerCase().includes(filters.version.toLowerCase())) &&
      (!filters.ownerEmail || entity.ownerEmail.toLowerCase().includes(filters.ownerEmail.toLowerCase()))
    ))
    .sort((a, b) =>
      (sort.asc && (a[sort.name] < b[sort.name])) || (!sort.asc && (a[sort.name] > b[sort.name])) ? -1 : 1
    );

  const [macForUpgrade, setMacForUpgrade] = useState(null);
  const { state: upgradeState, execute: upgradeExecute } = useApiRequest();
  useEffect(() => {
    if (upgradeState.success) {
      execute(endpoints.devices.loadList());
    }
  } , [upgradeState, execute]);

  const handlers = {
    onSortChange: (fieldName) => () => (sort.name === fieldName)
      ? setSort({ ...sort, asc: !sort.asc })
      : setSort({ name: fieldName, asc: true }),
    onFilterChange: (fieldName) => (e) => setFilters({ ...filters, [fieldName]: e.target.value }),

    onUpgrade: (mac) => () => setMacForUpgrade(mac),
    onUpgradeCancel: () => setMacForUpgrade(null),
    onUpgradeConfirm: () => {
      upgradeExecute(endpoints.devices.upgrade(macForUpgrade));
      setMacForUpgrade(null);
      // execute(endpoints.devices.loadList());
    },
  };

  return <View macForUpgrade={macForUpgrade} versions={versions} list={list} total={data.length}
               sort={sort} filters={filters} handlers={handlers} />;
};

export default Component;
