import config from '../../config';

export default {
  auth: {
    login: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/auth/login`, METHOD: 'POST', secure: false }),
    code: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/auth/code`, METHOD: 'POST' }),
    refreshToken: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/auth/refresh`, METHOD: 'GET' }),
    logout: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/auth/logout`, METHOD: 'GET' }),
    appLogin: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/auth/login/${id}`, METHOD: 'GET' }),
  },
  users: {
    load: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/users/${id}`, METHOD: 'GET' }),
    loadList: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/users/list`, METHOD: 'GET' }),
  },
  devices: {
    load: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/devices/${id}`, METHOD: 'GET' }),
    loadList: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/devices/list`, METHOD: 'GET' }),
    upgrade: (mac) => ({ URL: `${config.SYS_API_HTTP_ROOT}/devices/upgrade/${mac}`, METHOD: 'PUT' }),
  },
  messages: {
    loadAll: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/messages/list`, METHOD: 'GET' }),
    loadByUser: (userId) => ({ URL: `${config.SYS_API_HTTP_ROOT}/messages/list/${userId}`, METHOD: 'GET' }),
    create: (userId) => ({ URL: `${config.SYS_API_HTTP_ROOT}/messages/${userId}`, METHOD: 'POST' }),
  },
  macs: {
    load: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/macs/${id}`, METHOD: 'GET' }),
    loadList: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/macs/list`, METHOD: 'GET' }),
    create: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/macs`, METHOD: 'POST' }),
    update: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/macs/${id}`, METHOD: 'PUT' }),
    // delete: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/macs/${id}`, METHOD: 'DELETE' }),
  },
  subscriptions: {
    load: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/subscriptions/${id}`, METHOD: 'GET' }),
    loadList: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/subscriptions`, METHOD: 'GET' }),
    create: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/subscriptions`, METHOD: 'POST' }),
    update: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/subscriptions/${id}`, METHOD: 'PUT' }),
  },
  subscriptionEntities: {
    loadList: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/subscriptions/entities`, METHOD: 'GET' }),
  },
  firmware: {
    load: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/firmware/${id}`, METHOD: 'GET' }),
    loadList: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/firmware/list`, METHOD: 'GET' }),
    create: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/firmware`, METHOD: 'POST' }),
    update: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/firmware/${id}`, METHOD: 'PUT' }),
    // delete: (id) => ({ URL: `${config.SYS_API_HTTP_ROOT}/macs/${id}`, METHOD: 'DELETE' }),
  },
  app: {
    loadLatest: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/app/latest`, METHOD: 'GET' }),
    loadList: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/app/list`, METHOD: 'GET' }),
    create: () => ({ URL: `${config.SYS_API_HTTP_ROOT}/app`, METHOD: 'POST' }),
  },
};
