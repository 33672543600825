const joi = require('joi');

const schema = joi.object({
  mac: joi.string()
    .length(17)
    .required(),
  note: joi.string()
    .min(0)
    .max(255)
}).options({ abortEarly: false, stripUnknown: true });

export default schema;
