import React from 'react';

import { FormMessages, Form } from '../../../components';

const errors = {
  401: 'auth.login.error_401',
  403: 'auth.login.error_403',
};

const Component = ({ form, register, result, handlers, t }) => (
  <div className="form">
    <div className="card card-login">
      <div className="card-header">
        <div className="card-header">
          <h4 className="header text-center mt-1 mb-1">{t('auth.login.title')}</h4>
        </div>
      </div>
      <Form.Progress on={form.isSubmitting} />
      <div className="card-body">
        <FormMessages.Error result={result} errors={errors} onClear={handlers.onClearMessage} t={t} />
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="nc-icon nc-single-02"/>
            </span>
          </div>
          <input name="username" className="form-control" placeholder={t('auth.login.lbl_username')} ref={register} />
        </div>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="nc-icon nc-key-25"/>
            </span>
          </div>
          <input name="password" type="password" className="form-control" placeholder={t('auth.login.lbl_password')} ref={register} />
        </div>
      </div>
      <div className="card-footer">
        <div>
          <button
            onClick={handlers.onSubmit}
            disabled={!form.isValid || form.isSubmitting}
            className="btn btn-warning btn-block mb-3"
          >
            {t('auth.login.btn_submit')}
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
