import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import View from './view';
import { user } from '../../../../modules';
import { api as endpoints } from '../../../../utils/api';
import { form as apiForm } from '../../../../utils';
import schema from './schema';

const Component = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const api = endpoints.auth.code();
  const callback = (result) => dispatch(user.login.action(result));

  const { onSubmit, onClearMessage, form, register, errors, result } = apiForm.useApiForm(api, callback, schema);

  const handlers = {
    onSubmit,
    onLogout: () => dispatch(user.logout.action()),
    onClearMessage,
  };

  return <View form={form} register={register} errors={errors} result={result} handlers={handlers} t={t} />;
};

export default Component;
