import React from 'react';
import { useTranslation } from 'react-i18next';

import View from './view';

const Component = ({ authenticated }) => {
  const { t } = useTranslation();

  return <View authenticated={authenticated} t={t} />;
};

export default Component;
