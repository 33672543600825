import React from 'react';

import Chat from '../chat';
import { FilterField, SortField } from '../../../../components/Table';
// import moment from 'moment';

const getRowColor = (ownedCnt, sharedCnt) => {
  if ((ownedCnt === 0) && (sharedCnt === 0)) {
    return 'bg-danger';
  }
  return (ownedCnt === 0) ? 'bg-warning' : '';
};

// const getTimeColor = (date) => {
//   if (date === '-') {
//     return 'bg-danger';
//   }
//
//   if (moment().diff(moment(date), 'days') > 15) {
//     return 'bg-danger';
//   } else {
//     return (moment().diff(moment(date), 'days') > 5) ? 'bg-warning' : 'bg-light';
//   }
// };

const Component = ({ list, total, filters, sort, selectedUser, handlers }) => (
  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Users (<b className="text-info">{list.length} : {total}</b>)</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-bordered table-small">
                <thead className="text-primary">
                <tr>
                  <SortField className="col-1 text-left" name="email" label="E-mail" sort={sort} handlers={handlers} />
                  <SortField className="col-auto text-center" name="messages" label="Msg" sort={sort} handlers={handlers} />
                </tr>
                <tr>
                  <FilterField name="email" filters={filters} handlers={handlers} />
                  <th />
                </tr>
                </thead>
                <tbody>
                {list && list.map((data, idx) => (
                  <tr key={idx} className={getRowColor(data.owned, data.shared)}>
                    <td className={`text-left clickable ${selectedUser && (data.id === selectedUser.id) && 'bg-warning'}`}
                        onClick={handlers.onSetSelected(data)}>
                      {data.email}
                    </td>
                    <td className="text-right">{data.messages}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <Chat user={selectedUser} />
      </div>
    </div>
  </div>
);

export default Component;
