import React from 'react';
import { NavLink } from 'react-router-dom';

import { SortField, FilterField } from '../../../../components/Table';

const getRowColor = (ownerEmail, deviceId) => {
  if ((ownerEmail === '-') && (deviceId === '-')) {
    return 'bg-warning';
  }
  return (ownerEmail === '-') ? 'bg-danger' : '';
};

const Component = ({ list, total, filters, sort, handlers }) => (
  <div className="col-md-12">
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">Macs (<b className="text-info">{list.length} : {total}</b>)</h5>
          <div>
            <NavLink className="btn btn-round btn-info" to={`/macs/add`}>
              <i className="nc-icon nc-simple-add"/>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-small">
            <thead className="text-primary">
              <tr>
                <SortField className="col-auto text-center" name="mac" label="MAC" sort={sort} handlers={handlers} />
                <th className="col-auto text-center text-secondary">Note</th>
                <SortField className="col-auto text-center" name="ownerEmail" label="Owner" sort={sort} handlers={handlers} />
                <SortField className="col-1 text-center" name="deviceName" label="Device Name" sort={sort} handlers={handlers} />
                <SortField className="col-auto text-center" name="deviceId" label="Device Id" sort={sort} handlers={handlers} />
              </tr>
              <tr>
                <FilterField name="mac" filters={filters} handlers={handlers} />
                <th />
                <FilterField name="ownerEmail" filters={filters} handlers={handlers} />
                <FilterField name="deviceName" filters={filters} handlers={handlers} />
                <FilterField name="deviceId" filters={filters} handlers={handlers} />
              </tr>
            </thead>
            <tbody>
            {list && list.map((data, idx) => (
              <tr key={idx} className={getRowColor(data.ownerEmail, data.deviceId)}>
                <td className="text-center col-auto">
                  <NavLink className="text-danger" to={`/macs/edit/${data.id}`}>
                    {data.mac}
                  </NavLink>
                </td>
                <td className="text-center col-auto">{data.note}</td>
                <td className="text-left col-auto">{data.ownerEmail}</td>
                <td className="text-left col-auto text-nowrap">{data.deviceName}</td>
                <td className="text-center col-auto">{data.deviceId}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
