import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';

import View from './view';
import { useApiRequest } from '../../../../../utils/hooks';
import { api as endpoints } from '../../../../../utils/api';

const Component = () => {
  const { state, execute } = useApiRequest();
  useEffect(() => {
    execute(endpoints.macs.loadList());
  } , [execute]);

  const [ data, setData ] = useState([]);
  useEffect(() => {
    setData((isEmpty(state.data) ? [] : state.data).map(entity => ({
      ...entity,
      deviceId: entity.device ? entity.device._id : '-',
      deviceName: entity.device ? entity.device.name : '-',
      ownerEmail: (entity.device && entity.device.user) ? entity.device.user.email : '-',
    })));
  } , [state.data, setData]);

  const [ sort, setSort ] = useState({ name: '', asc: true });
  const [ filters, setFilters ] = useState({ id: '', mac: '', deviceId: '', deviceName: '', ownerEmail: '' });

  const list = data
    .filter(entity => (
      (!filters.id || entity.id.toLowerCase().includes(filters.id.toLowerCase())) &&
      (!filters.mac || entity.mac.toLowerCase().includes(filters.mac.toLowerCase())) &&
      (!filters.deviceId || entity.deviceId.toLowerCase().includes(filters.deviceId.toLowerCase())) &&
      (!filters.deviceName || entity.deviceName.toLowerCase().includes(filters.deviceName.toLowerCase())) &&
      (!filters.ownerEmail || entity.ownerEmail.toLowerCase().includes(filters.ownerEmail.toLowerCase()))
    ))
    .sort((a, b) =>
      (sort.asc && (a[sort.name] < b[sort.name])) || (!sort.asc && (a[sort.name] > b[sort.name])) ? -1 : 1
    );

  const handlers = {
    onSortChange: (fieldName) => () => (sort.name === fieldName)
      ? setSort({ ...sort, asc: !sort.asc })
      : setSort({ name: fieldName, asc: true }),
    onFilterChange: (fieldName) => (e) => setFilters({ ...filters, [fieldName]: e.target.value }),
  };

  return <View list={list} total={data.length} sort={sort} filters={filters} handlers={handlers} />;
};

export default Component;
