import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import moment from 'moment';

import View from './view';
import { useApiRequest } from '../../../../../utils/hooks';
import { api as endpoints } from '../../../../../utils/api';

const Component = () => {
  const { state, execute } = useApiRequest();
  useEffect(() => execute(endpoints.firmware.loadList()) , [execute]);

  const [ data, setData ] = useState([]);
  useEffect(() => {
    setData((isEmpty(state.data) ? [] : state.data).map(entity => ({
      ...entity,
      updated: moment(entity.created).format('YYYY-MM-DD HH:mm:ss'),
    })));
  } , [state.data, setData]);

  const [ sort, setSort ] = useState({ name: 'updated', asc: true });
  const [ filters, setFilters ] = useState({ type: '', version: '' });

  const list = data
    .filter(entity => (
      (!filters.type || entity.type.toLowerCase().includes(filters.type.toLowerCase())) &&
      (!filters.version || entity.version.toLowerCase().includes(filters.version.toLowerCase()))
    ))
    .sort((a, b) =>
      (sort.asc && (a[sort.name] < b[sort.name])) || (!sort.asc && (a[sort.name] > b[sort.name])) ? -1 : 1
    );

  const handlers = {
    onSortChange: (fieldName) => () => (sort.name === fieldName)
      ? setSort({ ...sort, asc: !sort.asc })
      : setSort({ name: fieldName, asc: true }),
    onFilterChange: (fieldName) => (e) => setFilters({ ...filters, [fieldName]: e.target.value }),
  };

  return <View list={list} total={data.length} sort={sort} filters={filters} handlers={handlers} />;
};

export default Component;
