import endpoints from './endpoints';
import { actions, createActionTypes } from './actions';
import { initState, handlers } from './handlers';
import middleware from './middleware';
import callApi from './callApi';

export const api = endpoints;
export { actions, createActionTypes };
export { initState, handlers };
export { callApi };

export default middleware;
