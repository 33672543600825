import React from 'react';

const View = ({ t }) => (
  <footer className="footer footer-black footer-white">
    <div className="container-fluid">
      <div className="row">
        <div className="credits ml-auto">
          <span className="copyright white">
            © 2019. {t('footer.made_with')} <i className="fa fa-heart heart"/> {t('footer.by')}
          </span>
        </div>
      </div>
    </div>
  </footer>
);

export default View;
