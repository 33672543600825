import { pathOr } from 'ramda';

import { ACTION_TYPE, getHandlers } from './actions';
import callApi from './callApi';

const AUTH_STATE_PATH = ['user'];

const middleware = (store) => (next) => async (action) => {
  if (action.type === ACTION_TYPE) {
    const { api, body, namespace, handlers } = action.payload;
    const { onRequest, onSuccess, onFailure } = getHandlers(namespace, handlers || {});
    const user = pathOr({}, AUTH_STATE_PATH)(store.getState());
    const pendingFn = () => store.dispatch(onRequest());
    const successFn = (result) => store.dispatch(onSuccess(result));
    const errorFn = (error) => store.dispatch(onFailure(error));
    await callApi(api, body, user, pendingFn, successFn, errorFn);
  }
  next(action);
};

export default middleware;
