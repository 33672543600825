import React from 'react';

import Header from './header';
import Menu from './menu';
import Content from './content';
import Footer from './footer';

const Component = () => (
  <div className="wrapper">
    <Menu />
    <div className="main-panel">
      <Header />
      <Content />
      <Footer />
    </div>
  </div>
);

export default Component;
