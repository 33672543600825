import React from 'react';

const Component = ({ visible, handlers, Component, props }) => (
  <>
    <Component {...{ ...props, visible, handlers } } />
    {visible && <div className={`modal-backdrop fade show`} />}
  </>
);

export default Component;
