import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import View from './view';
import schema from './schema';
import { useApiRequest } from '../../../../../utils/hooks';
import * as messenger from '../../../../../utils/messenger';
import { api as endpoints } from '../../../../../utils/api';

const Component = ({ user }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const apiCallback = useCallback((result) => setMessages(result), []);

  const messageCallback = (msg) => {
    const { c: command, message } = JSON.parse(msg);
    if (command === 'chat') {
      setNewMessage(message);
    }
  };

  useEffect(() => {
    if (newMessage !== '') {
      setNewMessage('');
      setMessages([...messages, newMessage]);
    }
  }, [messages, newMessage]);

  const { execute: getAllMessages } = useApiRequest(apiCallback);

  useEffect(() => {
    if (user) {
      getAllMessages(endpoints.messages.loadByUser(user.id));
    }
  }, [getAllMessages, user]);

  useEffect(() => {
    if (user) {
      messenger.subscribe([{ name: `user/${user.id}`, callback: messageCallback }]);
      return () => {
        messenger.unsubscribe([{ name: `user/${user.id}`, callback: messageCallback }]);
      }
    }
  }, [user]);

  const { state, resetState, execute } = useApiRequest();
  const form = useForm({ mode: 'all', resolver: joiResolver(schema) });
  const handlers = {
    onSubmit: (data) => {
      execute(endpoints.messages.create(user.id), data);
      form.reset();
      form.control.fieldsRef.current.text.ref.focus();
    },
    onReset: resetState,
  };

  // hack: formValid={form.formState.isValid} - this triggers form validation, but otherwise it's useless.
  // find normal solution.
  return <View user={user} chat={messages} form={form}
               formValid={form.formState.isValid} state={state} handlers={handlers} />;
};

export default Component;
