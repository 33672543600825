import React from 'react';
import { isEmpty } from 'ramda';
import moment from 'moment';

import { Form } from '../../../../components';

const Component = ({ user, chat, form, state, handlers }) => (
  <div className="card">
    <div className="card-header">
      <h5 className="card-title">
        {user
          ? <b className="text-info">{user.email}</b>
          : <span>Select user for chat</span>
        }
      </h5>
    </div>
    {chat && !isEmpty(chat) && (
      <div className="m-3">
        {chat.map((message, idx) => (
          <div key={idx} className={`card ${message.outgoing ? 'bg-light mr-5' : 'bg-info ml-5 text-white'}`}>
            <div className="card-header pl-3 text-sm">
              {moment(message.created).format('YYYY-MM-DD HH:mm:ss')}
            </div>
            <div className="card-body text-sm">
              <pre className={message.outgoing ? '' : 'text-white'}>{message.text}</pre>
            </div>
          </div>
        ))}
      </div>
    )}
    {user && (
      <>
        <div className="card-body">
          <form onSubmit={form.handleSubmit(handlers.onSubmit)} onChange={handlers.onReset}>
            <textarea name="text" ref={form.register} maxLength={255} placeholder="Type your message here" rows="5"/>
          </form>
        </div>
        <div className="card-footer border-top">
          <Form.Submit className="btn btn-info float-right" form={form} state={state} label="Send"
                       onSubmit={form.handleSubmit(handlers.onSubmit)} />
        </div>
      </>
    )}
  </div>
);

export default Component;
