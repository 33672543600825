// import TYPES from './types';

// const DEFAULT_TIMEOUT = 3000;

// TODO: refactor messaging... I don't like this. remove?
const action = messenger => ({ to, topic, message }) => async (dispatch) => {
  //   dispatch({ type: TYPES.SEND.REQUEST, payload: { id: to, topic } });
  //   setTimeout(
  //     () => {
  //       dispatch({ type: TYPES.SEND.FAILURE, payload: { id: to, topic, error: { timeout: true } } });
  //       // dispatch({ type: ALL_TYPES.LOAD.SUCCESS, payload: { id: to, data: { connected: false } } });
  //     },
  //     timeout
  //   );
  // }
  // console.log('SEND MESSAGE!!!!', topic, message);
  // messenger.send(`${to}/${topic}`, message);
};

export default action;
