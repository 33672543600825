import React, { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';

import View from './view';
import { useApiRequest } from '../../../../../utils/hooks';
import { api as endpoints } from '../../../../../utils/api';

const Component = () => {
  const { state, execute } = useApiRequest();
  useEffect(() => execute(endpoints.users.loadList()), [execute]);
  const { state: msgState, execute: msgExecute } = useApiRequest();
  useEffect(() => msgExecute(endpoints.messages.loadAll()), [msgExecute]);

  const [ data, setData ] = useState([]);
  useEffect(() => {
    setData((isEmpty(state.data) ? [] : state.data).map(entity => ({
      ...entity,
      messages: msgState.data.filter(message => `${message.userId}` === `${entity._id}`).length,
    })));
  } , [state.data, msgState.data, setData]);

  const [ sort, setSort ] = useState({ name: '', asc: true });
  const [ filters, setFilters ] = useState({ email: '' });

  const total = data.length;
  const list = data
    .filter(entity => (
      (!filters.email || entity.email.toLowerCase().includes(filters.email.toLowerCase()))
    ))
    .sort((a, b) =>
      (sort.asc && (a[sort.name] < b[sort.name])) || (!sort.asc && (a[sort.name] > b[sort.name])) ? -1 : 1
    );

  const [selectedUser, setSelectedUser] = useState(null);

  const handlers = {
    onSortChange: (fieldName) => () => (sort.name === fieldName)
      ? setSort({ ...sort, asc: !sort.asc })
      : setSort({ name: fieldName, asc: true }),
    onFilterChange: (fieldName) => (e) => setFilters({ ...filters, [fieldName]: e.target.value }),

    onSetSelected: (user) => () => setSelectedUser(user),
  };

  return <View list={list} total={total} sort={sort} filters={filters} selectedUser={selectedUser} handlers={handlers} />;
};

export default Component;
