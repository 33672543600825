import { indexBy, prop } from 'ramda';

import { actions, createActionTypes, api } from '../../utils/api';

const NAMESPACE = 'LOAD_LIST_DEVICES';
const TYPES = createActionTypes(NAMESPACE);

const handlers = ({
  // TODO: remove 'verified' prop in BE, remove all this afterwards.
  onSuccess: (data) => (dispatch) => dispatch({ type: TYPES.SUCCESS, payload: indexBy(prop('id'))(data) }),
});

const action = () => (dispatch) => {
  console.log('----- loading list');
  dispatch(actions.callAPI(NAMESPACE, api.device.loadList(), null, handlers));
};

export default { action, TYPES };
