import * as mqtt from 'mqtt';

import config from '../../config';

let mqttClient = null;
let subscribedTo = {};

const getClientId = () => `sys-app-${Math.random().toString(16).substr(2, 8)}`;
const options = { username: config.MQTT_USERNAME, password: config.MQTT_PASSWORD, clientId: getClientId() };

const connect = (topics) => {
  mqttClient = mqtt.connect(config.MQTT_ROOT, options);

  mqttClient.on('connect', () => {
    listen(topics);
  });

  mqttClient.on('message', (topic, message) => {
    if (subscribedTo[topic] && subscribedTo[topic] instanceof Function) {
      subscribedTo[topic](message.toString());
    }
  });
};

const listen = (topics) => {
  topics.forEach(({ name, callback }) => mqttClient.subscribe(name, (err) => {
    subscribedTo = { ...subscribedTo, [name]: callback };
    if (err !== null) {
      console.log(`ERROR subscribing to topic: ${name}`, err);
    }
  }));
};

const subscribe = (topics) => {
  if (mqttClient && mqttClient.connected) {
    listen(topics);
  } else {
    connect(topics);
  }
};

const unsubscribe = (topics) => {
  if (mqttClient && mqttClient.connected) {
    topics.forEach(({ name }) => {
      // TODO: remove topic
      // subscribedTo = { ...subscribedTo, [name]: callback };
      mqttClient.unsubscribe(name, () => {});
    });
  }
};

const publish = (topic, message) => {
  if (mqttClient.connected) {
    mqttClient.publish(topic, JSON.stringify(message));
  } else {
    console.log('MQTT not connected');
  }
};

export { subscribe, unsubscribe, publish };
