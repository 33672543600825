const initState = ({ data = [], ...rest }) => ({
  ...rest,
  data,
  pending: false,
  error: null,
});

const handlers = (TYPES) => ({
  [TYPES.REQUEST]: (state) => ({
    ...state,
    pending: true,
    error: null,
  }),
  [TYPES.SUCCESS]: (state, payload) => ({
    ...state,
    data: payload,
    pending: false,
    error: null,
  }),
  [TYPES.FAILURE]: (state, payload) => ({
    ...state,
    pending: false,
    error: payload,
  }),
});

export { initState, handlers };
