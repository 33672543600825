import { actions, createActionTypes, api } from '../../utils/api';
import { CONNECTION_MODE } from './constants';
import setConnectionMode from './setConnectionMode';
import * as registration from '../devices/registration';

const NAMESPACE = 'PING_NETWORK';
const TYPES = createActionTypes(NAMESPACE);

const ERROR_MODE_MAP = {
  403: CONNECTION_MODE.ONLINE,
};

const handlers = ({
  onSuccess: (payload) => (dispatch) => {
    if (payload.length === 17) {
      dispatch(setConnectionMode.action(CONNECTION_MODE.PAIRED));
      dispatch(registration.setPaired.action(payload));
    }
  },
  onFailure: (error) => (dispatch) => {
    const mode = ERROR_MODE_MAP[error.status] || CONNECTION_MODE.OFFLINE;
    dispatch(setConnectionMode.action(mode));
    if (mode === CONNECTION_MODE.OFFLINE) {
      // disconnected while pinging connection vs physical device in registration
      dispatch(registration.disconnected.action());
    }
  },
});

const action = () => (dispatch) =>
  dispatch(actions.callAPI(NAMESPACE, api.device.physical.pair(), null, handlers));

export default { action, TYPES };
