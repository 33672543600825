import appendSerial from './appendSerial';
import setState from './setState';
import reset from './reset';

const TYPE = 'HANDLE_DEVICE_COMMAND';

const COMMAND_ACTION = {
  status: setState.action,
  serial: appendSerial.action,
  reset: reset.action,
};

const action = ({ id, code, command }) => (dispatch) => {
  console.log('HANDLE COMMAND:', id, code, command);
  dispatch(COMMAND_ACTION[code]({ id, data: command }));
};

export default { action, TYPE };
