import React from 'react';
import moment from 'moment';

import { SortField, FilterField } from '../../../../components/Table';

const getRowColor = (ownedCnt, sharedCnt) => {
  if ((ownedCnt === 0) && (sharedCnt === 0)) {
    return 'bg-danger';
  }
  return (ownedCnt === 0) ? 'bg-warning' : '';
};

const getTimeColor = (date) => {
  if (date === '-') {
    return 'bg-danger';
  }

  if (moment().diff(moment(date), 'days') > 15) {
    return 'bg-danger';
  } else {
    return (moment().diff(moment(date), 'days') > 5) ? 'bg-warning' : 'bg-light';
  }
};

const Component = ({ list, total, filters, sort, handlers }) => (
  <div className="col-md-12">
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Users (<b className="text-info">{list.length} : {total}</b>)</h5>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-small">
            <thead className="text-primary">
            <tr>
              <SortField className="col-auto text-center" name="id" label="Id" sort={sort} handlers={handlers} />
              <SortField className="col-1 text-left" name="email" label="E-mail" sort={sort} handlers={handlers} />
              <SortField className="col-auto text-center" name="owned" label="Owned" sort={sort} handlers={handlers} />
              <SortField className="col-auto text-center" name="shared" label="Shared" sort={sort} handlers={handlers} />
              <SortField className="col-auto text-center" name="created" label="Created" sort={sort} handlers={handlers} />
              <SortField className="col-auto text-center" name="activity" label="Activity" sort={sort} handlers={handlers} />
              <th className="text-center">@</th>
            </tr>
            <tr>
              <FilterField name="id" filters={filters} handlers={handlers} />
              <FilterField name="email" filters={filters} handlers={handlers} />
              <FilterField name="owned" filters={filters} handlers={handlers} />
              <FilterField name="shared" filters={filters} handlers={handlers} />
              <th />
              <th />
              <th />
            </tr>
            </thead>
            <tbody>
            {list && list.map((data, idx) => (
              <tr key={idx} className={getRowColor(data.owned, data.shared)}>
                <td className="text-center">{data.id}</td>
                <td className="text-left">{data.email}</td>
                <td className="text-right">{data.owned}</td>
                <td className="text-right">{data.shared}</td>
                <td className="text-center text-nowrap">{data.created}</td>
                <td className={`text-center text-nowrap ${getTimeColor(data.lastActivity)}`}>
                  {data.lastActivity}
                </td>
                <td className="text-center text-nowrap">
                  <button className="btn btn-xsm btn-info" onClick={handlers.onLogin(data.id)}>
                    <i className="nc-icon nc-button-play"/>
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
