import reducer from '../utils/reducer';
import { initState, handlers as apiHandlers } from '../utils/api';
import { devices } from '../modules';

const initialState = initState({ data: {} });

const handlers = {
  ...apiHandlers(devices.registration.getNetworkList.TYPES),

  [devices.registration.disconnected.TYPE]: () => initialState,
};

export default reducer(handlers, initialState);
