import React from 'react';

import * as Content from './content';
import logo from '../../public/assets/img/logo.svg';
import config from '../../config';
import Footer from './footer';

const Component = ({ authenticated, t }) => (
  <div className="wrapper wrapper-full-page theme-bg">
    <div className="full-page">
      <header className="header-area header_fix">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-6">
              <div className="logo">
                <a style={{float: 'left'}} href={config.WEB_ROOT}>
                  <img src={logo} alt="logo"/>
                </a>
                <div className="logo-title">
                  <a href={config.WEB_ROOT}>
                    <h2 className="res-hidden">Eltrum</h2>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-6">
              <div className="header-btn"/>
            </div>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <div className="col-lg-4 col-md-6 ml-auto mr-auto">
            {authenticated ? <Content.Code /> : <Content.Login />}
          </div>
        </div>
      </div>
    </div>
    <Footer t={t} />
  </div>
);

export default Component;
