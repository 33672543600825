import React from 'react';

import { Form, FormMessages } from '../../../components';

const errors = {
  401: 'auth.code.error_401',
  403: 'auth.code.error_403',
};

const Component = ({ form, register, result, handlers, t }) => (
  <div className="form">
    <div className="card card-login">
      <div className="card-header">
        <div className="card-header">
          <h4 className="header text-center mt-1 mb-1">{t('auth.code.title')}</h4>
        </div>
      </div>
      <Form.Progress on={form.isSubmitting} />
      <div className="card-body">
        <FormMessages.Error result={result} errors={errors} onClear={handlers.onClearMessage} t={t} />
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="nc-icon nc-single-02"/>
            </span>
          </div>
          <input name="code" className="form-control" placeholder={t('auth.code.lbl_code')} ref={register} />
        </div>
      </div>
      <div className="card-footer">
        <div>
          <button
            onClick={handlers.onSubmit}
            disabled={!form.isValid || form.isSubmitting}
            className="btn btn-warning btn-block mb-3"
          >
            {t('auth.code.btn_submit')}
          </button>
          <button
            onClick={handlers.onLogout}
            className="btn btn-danger btn-block mb-3"
          >
            {t('auth.code.btn_logout')}
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
