import React from 'react';
import { useDispatch } from 'react-redux';

import View from './view';
import { user } from '../../../modules';

const Component = () => {
  const dispatch = useDispatch();
  const handlers = {
    onLogout: () => dispatch(user.logout.action()),
  };

  return <View handlers={handlers} />;
};

export default Component;
