import { actions, createActionTypes, api } from '../../../utils/api';
import { constants, setConnectionMode } from '../../app';
import { CONNECTION_MODE } from '../../app/constants';
import disconnected from './disconnected';

const NAMESPACE = 'REGISTRATION_GET_NETWORK_LIST';
const TYPES = createActionTypes(NAMESPACE);

const ERROR_MODE_MAP = {
  404: constants.CONNECTION_MODE.PAIRED,
  403: constants.CONNECTION_MODE.ONLINE,
};

const handlers = ({
  onSuccess: (data) => (dispatch) => {
    dispatch(setConnectionMode.action(constants.CONNECTION_MODE.PAIRED));
    dispatch({ type: TYPES.SUCCESS, payload: data });
  },
  onFailure: (error) => (dispatch) => {
    const mode = ERROR_MODE_MAP[error.status] || CONNECTION_MODE.OFFLINE;
    dispatch(setConnectionMode.action(mode));
    if (mode === CONNECTION_MODE.OFFLINE) {
      // disconnected while retrieving network list in registration
      dispatch(disconnected.action());
    }
    dispatch({ type: TYPES.FAILURE, payload: error });
  },
});

const action = () => (dispatch) =>
  dispatch(actions.callAPI(NAMESPACE, api.device.physical.getNetworkList(), null, handlers));

export default { action, TYPES };
