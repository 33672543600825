import React from 'react';
import './styles.css';

const View = ({ isVisible }) => (
  isVisible
    ? (
      <div className="loader">
        <div className="slider"></div>
      </div>
    )
    : <hr className="m-0" />
);

export default View;
