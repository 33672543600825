import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';

// import config from '../config';
// import { messenger } from '../modules';
import reducers from '../state';
import apiMiddleware from './api';
// import { createMiddleware } from './messenger';

// const messengerMiddleware = createMiddleware(config.MQTT_OPTIONS, messenger.handleMessage);
const store = createStore(reducers, applyMiddleware(
  apiMiddleware,
  // messengerMiddleware,
  thunkMiddleware
));
const persistor = persistStore(store);

export default {
  store,
  persistor,
};
