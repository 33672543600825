import { actions, api, createActionTypes } from '../../../utils/api';

const NAMESPACE = 'SAVE_GROUPS';
const TYPES = createActionTypes(NAMESPACE);

const handlers = ({
  // onSuccess: (data) => (dispatch) => {
  //   console.log('?!?! SAVE!', data);
  //   // dispatch({ type: TYPES.SUCCESS, payload: indexBy(prop('id'))(data) })
  // },
});

const action = (data) => (dispatch) =>
  dispatch(actions.callAPI(NAMESPACE, api.device.groups.save(), data, handlers));

export default { action, TYPES };
