import React from 'react';

const Component = () => (
  <footer className="footer footer-black footer-white ">
    <div className="container-fluid">
      <div className="row">
        <div className="credits ml-auto">
        <span className="copyright">
          ©2020, made with <i className="fa fa-heart heart" /> by Eltrum Systems
        </span>
        </div>
      </div>
    </div>
  </footer>
);

export default Component;
