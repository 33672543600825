const joi = require('joi');

const schema = joi.object({
  type: joi.string()
    .min(3)
    .max(20)
    .required(),
  version: joi.string()
    .min(3)
    .max(10)
    .required(),
}).options({ abortEarly: false, stripUnknown: true });

export default schema;
