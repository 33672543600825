import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';
import lt from './lt';

const DEFAULT_LANGUAGE = 'lt';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    lt: {
      translation: lt
    }
  },
  lng: DEFAULT_LANGUAGE,
  fallbackLng: "en",

  interpolation: {
    escapeValue: false
  },
  // useSuspense: false,
});

export default DEFAULT_LANGUAGE;
