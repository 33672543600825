import { omit, pathOr } from 'ramda';

import reducer from '../utils/reducer';
import { initState, handlers as apiHandlers } from '../utils/api';
import { user, devices } from '../modules';

const initialState = initState({ data: {} });

const MAX_SERIAL = 20;

const handlers = {
  ...apiHandlers(devices.loadList.TYPES),

  [devices.save.TYPE]: (state, payload) => ({
    ...state,
    data: {
      ...state.data,
      [payload.id]: payload,
    },
  }),

  [devices.remove.TYPE]: (state, payload) => ({
    ...state,
    data: omit([payload.id], state.data),
  }),

  // TODO: refactor with ramda
  [devices.commands.setState.TYPE]: (state, payload) => state.data[payload.id] ? ({
    ...state,
    data: {
      ...state.data,
      [payload.id]: {
        ...state.data[payload.id],
        data: {
          ...state.data[payload.id].data,
          ...payload.data,
        },
      },
    },
  }) : state,

  // TODO: refactor with ramda
  [devices.commands.appendSerial.TYPE]: (state, payload) => state.data[payload.id] ? ({
    ...state,
    data: {
      ...state.data,
      [payload.id]: {
        ...state.data[payload.id],
        serial: (!state.data[payload.id].serial || state.data[payload.id].serial.length < MAX_SERIAL)
          ? [...pathOr([], ['data', payload.id, 'serial'])(state), payload.data]
          : [...state.data[payload.id].serial.slice(1, MAX_SERIAL), payload.data],
      },
    },
  }) : state,

  [devices.commands.reset.TYPE]: (state, payload) => ({
    ...state,
    data: omit([payload.id], state.data),
  }),

  [user.logout.TYPE]: () => initialState,
};

export default reducer(handlers, initialState);
