import React from 'react';

const systemErrors = {
  500: 'system.error_500',
};

const Component = ({ result, errors, onClear, t }) => {
  errors = { ...systemErrors, ...errors };
  if (result && result.error) {
    console.log('--- ERROR:', result.message);
  }
  return result && result.error ? (
    <div className="alert alert-danger alert-dismissible fade show" data-notify="container">
      <button type="button" aria-hidden="true" className="close" data-dismiss="alert" onClick={onClear}>
        <i className="nc-icon nc-simple-remove"/>
      </button>
      <span data-notify="message">
      {errors[result.status] ? t(errors[result.status]) : t(errors[500])}
    </span>
    </div>
  ) : null;
};

export default Component;
