import reducer from '../utils/reducer';
import { app } from '../modules';

const initialState = {};

const handlers = {
  [app.setConfigParam.TYPE]: (state, payload) => ({ ...state, ...payload }),
};

export default reducer(handlers, initialState);
