import React, { Fragment } from 'react';
import { ENTITY_TYPE_MAP } from '../../../../../../lib/subscriptions';

const Component = ({ visible, data, showDetails, handlers, t }) => (
  <div className={`modal fade ${visible ? 'show' : 'hide'}`}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header bg-light p-2 align-items-center text-danger font-weight-bold border-bottom-0">
          <span className="modal-title">
            Subscription Details
          </span>
          <button type="button" className="close" onClick={handlers.onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="container my-3">
          {data?.totals ? (
            <>
              <div className="row justify-content-between mx-0">
                    <span onClick={handlers.onDetailsToggle} className="clickable text-info">
                      <b><u>Suma ({data.totals.from} - {data.totals.to})</u></b>
                    </span>
                <span>{data.totals.amount.toFixed(2)}€</span>
              </div>
              <hr className="my-1"/>
              {showDetails && (
                <div className="w-100">
                  {data.monthly.map((record) => (
                    <Fragment key={record.month}>
                      <div className="text-sm"><b>{record.month}:</b></div>
                      <div className="text-sm mx-2">
                        {record.entities.map((entity, idx) => (
                          <Fragment key={entity.type + idx}>
                            <div className="d-flex justify-content-between mx-0">
                              <span>{ENTITY_TYPE_MAP[entity.type]} ({entity.count} x {entity.cost.toFixed(2)}€)</span>
                              <span>{entity.total.toFixed(2)}€</span>
                            </div>
                          </Fragment>
                        ))}
                        <div className="d-flex justify-content-between mx-0">
                          <span><b>Viso</b></span>
                          <span><b>{record.total.toFixed(2)}€</b></span>
                        </div>
                        <hr className="my-1"/>
                      </div>
                    </Fragment>
                  ))}

                  <div className="row justify-content-between mx-0">
                        <span className="">
                          Suma ({data.totals.from} - {data.totals.to})
                        </span>
                    <span>{data.totals.amount.toFixed(2)}€</span>
                  </div>

                  <hr className="my-1"/>
                </div>
              )}
              <div className="row justify-content-between mx-0">
                <span>PVM</span>
                <span>{data.totals.vat.toFixed(2)}€</span>
              </div>
              <hr className="my-1"/>
              <div className="row justify-content-between mx-0">
                <span><b>Viso</b></span>
                <span><b>{data.totals.total.toFixed(2)}€</b></span>
              </div>
            </>
          ) : (
            <span>Periodinių mokėjimų nėra.</span>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default Component;
