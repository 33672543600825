import reducer from '../utils/reducer';
import { devices } from '../modules';

const initialState = {
  id: null,
  data: null,
  // this one is used when app was closed on registration and reopened - redirects to registration
  started: false,
  // paired: false,
  // this one identifies if device has connected to specified WiFi
  connected: false,
  verified: false,
  fatal: false,

  failurePending: false,
  failure: null,
};

const handlers = {
  // is used when registration window is OPENED or CLOSED
  [devices.registration.open.TYPE]: (state, payload) => ({
    ...initialState,
    // this one is needed if registration is opened when device is already paired in main screen
    // (or app is reopened?)
    // TODO: there's a bug, ID persists when entering registration in ONLINE mode...
    id: state.id,
    started: payload,
  }),

  // is used when app disconnects inside registration
  [devices.registration.disconnected.TYPE]: (state) => ({
    ...initialState,
    id: state.connected ? state.id : null,
    started: state.started,
  }),

  // set MAC when paired with device
  [devices.registration.setPaired.TYPE]: (state, payload) => ({
    ...initialState,
    id: payload,
    started: state.started,
  }),

  [devices.getFailure.TYPES.REQUEST]: (state) => ({
    ...state,
    failurePending: true,
  }),

  [devices.getFailure.TYPES.FAILURE]: (state) => ({
    ...state,
    failurePending: false,
  }),

  [devices.getFailure.TYPES.SUCCESS]: (state, payload) => ({
    ...state,
    failurePending: false,
    failure: payload,
  }),

  // TODO: review those. do wee need them?
  [devices.registration.setData.TYPE]: (state, payload) => ({
    ...state,
    data: payload,
  }),

  [devices.registration.setDeviceConnected.TYPE]: (state, payload) => ({
    ...state,
    connected: payload,
  }),

  [devices.registration.setDeviceVerified.TYPE]: (state, payload) => ({
    ...state,
    verified: payload,
  }),

  [devices.registration.setFatal.TYPE]: (state, payload) => ({
    ...state,
    fatal: payload,
  }),
};

export default reducer(handlers, initialState);
