import React from 'react';

import { FilterField, SortField } from '../../../../components/Table';
import { NavLink } from 'react-router-dom';

const Component = ({ list, total, filters, sort, handlers }) => (
  <div className="col-md-6">
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">Firmware (<b className="text-info">{list.length} : {total}</b>)</h5>
          <div>
            <NavLink className="btn btn-round btn-info" to={`/firmware/add`}>
              <i className="nc-icon nc-simple-add"/>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-small">
            <thead className="text-primary">
              <tr>
                <SortField className="col-auto text-left" name="type" label="Type" sort={sort} handlers={handlers} />
                <SortField className="col-auto text-left" name="version" label="Version" sort={sort} handlers={handlers} />
                <SortField className="col-auto text-center" name="updated" label="Updated" sort={sort} handlers={handlers} />
              </tr>
              <tr>
                <FilterField name="type" filters={filters} handlers={handlers} />
                <FilterField name="version" filters={filters} handlers={handlers} />
                <th />
              </tr>
            </thead>
            <tbody>
            {list && list.map((data, idx) => (
              <tr key={idx}>
                <td className="text-left">
                  <NavLink className="text-danger" to={`/firmware/edit/${data.id}`}>
                    {data.type}
                  </NavLink>
                </td>
                <td className="text-right pr-3">{data.version}</td>
                <td className="text-center text-nowrap">{data.updated}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
