import { actions, createActionTypes, api } from '../../../utils/api';

const NAMESPACE = 'LOAD_GROUPS';
const TYPES = createActionTypes(NAMESPACE);

const handlers = ({
  onSuccess: (data) => (dispatch) => dispatch({ type: TYPES.SUCCESS, payload: data }),
});

const action = () => (dispatch) =>
  dispatch(actions.callAPI(NAMESPACE, api.device.groups.load(), null, handlers));

export default { action, TYPES };
