import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import List from './list';

const Component = () => (
  <Switch>
    <Route path="/messages"><List /></Route>
    <Redirect to="/messages" />
  </Switch>
);

export default Component;
