import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as queryString from 'query-string';
import { isEmpty, pathOr } from 'ramda';

import config from '../config';
import { app } from '../modules';
import View from './view';
import { security } from '../utils';

const useLanguage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { lang } = queryString.parse(location.search);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const storedLanguage = useSelector((state) => pathOr(config.LANG, ['language', 'code'])(state));
  if (lang && (storedLanguage !== lang)) {
    new Promise(() => i18n.changeLanguage(`${lang}`)).then(() => {});
    dispatch(app.setLanguage.action(lang));
  } else if (currentLanguage !== storedLanguage) {
    new Promise(() => i18n.changeLanguage(storedLanguage)).then(() => {});
  }
};

const Component = () => {
  useLanguage();

  const user = useSelector((state) => pathOr({}, ['user'])(state));
  const authenticated = !isEmpty(user);
  const confirmed = security.has2FA(user);
  return <View authenticated={authenticated} confirmed={confirmed} />;
};

export default Component;
