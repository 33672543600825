import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import View from './view';

const Component = ({ visible, handlers, data }) => {
  const { t } = useTranslation();

  const [showDetails, setShowDetails] = useState(true);

  const allHandlers = {
    onDetailsToggle: () => setShowDetails(!showDetails),
    ...handlers,
  };

  return <View visible={visible} data={data} showDetails={showDetails} handlers={allHandlers} t={t} />;
};

export default Component;
