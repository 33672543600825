import React from 'react';

const Component = ({ result, onClear, children }) => result && !result.error ? (
  <div className="alert alert-info alert-with-icon alert-dismissible fade show" data-notify="container">
    <button type="button" aria-hidden="true" className="close" data-dismiss="alert" onClick={onClear}>
      <i className="nc-icon nc-simple-remove"/>
    </button>
    <span data-notify="icon" className="nc-icon nc-check-2"/>
    <span data-notify="message">
      {children}
    </span>
  </div>
) : null;

export default Component;
