import React from 'react';

const Component = ({ handlers }) => (
  <nav className="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
    <div className="container-fluid">
      {/*<div className="navbar-wrapper">*/}
      {/*  <div className="navbar-minimize">*/}
      {/*    <button id="minimizeSidebar" className="btn btn-icon btn-round">*/}
      {/*      <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"/>*/}
      {/*      <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"/>*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="collapse navbar-collapse justify-content-end" id="navigation">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a href="/" className="nav-link nav-top" onClick={handlers.onLogout}>
              <i className="nc-icon nc-button-power"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

export default Component;
