import reducer from '../utils/reducer';
import { handlers as apiHandlers, initState } from '../utils/api';
import { app } from '../modules';

const initialState = initState({
  data: null,
  mode: app.constants.CONNECTION_MODE.OFFLINE,
});

const handlers = {
  ...apiHandlers(app.pingNetwork.TYPES),

  [app.setConnectionMode.TYPE]: (state, payload) => ({
    ...state,
    pending: false,
    mode: payload,
  }),
};

export default reducer(handlers, initialState);
