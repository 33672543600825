import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Users from './users';
import Devices from './devices';
import Macs from './macs';
import Subscriptions from './subscriptions';
import Messages from './messages';
import Firmware from './firmware';
import App from './app';

const Component = () => (
  <div className="content">
    <div className="row">
      <Switch>
        <Route path="/users"><Users /></Route>
        <Route path="/devices"><Devices /></Route>
        <Route path="/macs"><Macs /></Route>
        <Route path="/subscriptions"><Subscriptions /></Route>
        <Route path="/messages"><Messages /></Route>
        <Route path="/firmware"><Firmware /></Route>
        <Route path="/app"><App /></Route>
        <Redirect to="/users" />
      </Switch>
    </div>
  </div>
);

export default Component;
