import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import List from './list';
import Form from './form';

const Component = () => (
  <Switch>
    <Route path="/app/add"><Form /></Route>
    <Route path="/app"><List /></Route>
    <Redirect to="/app" />
  </Switch>
);

export default Component;
