import React, { useCallback, useEffect, useState } from 'react';

import View from './view';
import { useSelector } from 'react-redux';
import { callApi } from '../../../utils/api';

const defaultApiState = {
  success: false,
  pending: false,
  error: false,
  errorMsg: '',
};

const useModalForm = (formSchema, defaultFormState, visible) => {
  const [data, setData] = useState(defaultFormState);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const { error } = formSchema.validate(data);
    if (error) {
      const { details } = error;
      setFormErrors(details.reduce((res, { context, message }) => ({
        ...res,
        [context.key]: message,
      }), {}));
    } else {
      setFormErrors({});
    }
  }, [data, formSchema]);

  // useEffect(() => {
  //   if (!visible) {
  //     setData(defaultFormState);
  //     setFormErrors({});
  //   }
  // }, [defaultFormState, visible]);

  return {
    data,
    setData,
    formErrors,
  };
};

const useModalApi = (handlers) => {
  const user = useSelector((state) => state?.user || {});
  const [apiResults, setApiResults] = useState(defaultApiState);

  const callModalApi = async (apiEndpoint, body) => {
    setApiResults({ ...defaultApiState, pending: true });
    const result = await callApi(apiEndpoint, body, user);
    if (result.error) {
      setApiResults({ ...defaultApiState, pending: false, error: true, errorMsg: result.errorMsg });
    } else {
      setApiResults({ ...defaultApiState, pending: false, success: true });
      handlers.onClose();
    }
  }

  return {
    apiResults,
    callModalApi,
  }
};

const useModal = (ModalComponent, props) => {
  const [visible, setVisible] = useState(false);
  const onOpen = useCallback(() => {
    setVisible(true);
    document.body.style.overflow = 'hidden';
  }, []);
  const onClose = useCallback(() => {
    setVisible(false);
    document.body.style.overflow = '';
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (visible) {
      document.addEventListener('keydown', escFunction);
    } else {
      document.removeEventListener('keydown', escFunction);
    }
  }, [visible, escFunction]);

  const handlers = {
    ...props.handlers,
    onOpen,
    onClose,
  };
  const Modal = <View visible={visible} handlers={handlers} Component={ModalComponent} props={props} />;

  return { Modal, onOpen, onClose };
};

export {
  useModalForm,
  useModalApi,
  useModal,
};
