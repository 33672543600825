import React from 'react';
import { LoaderBar } from '../../../../../components/LoaderBar';
import { ENTITY_TYPE_MAP } from '../../../../../../lib/subscriptions';

const Component = ({ visible, data, formData, formErrors, apiResults, handlers, t }) => (
  <div className={`modal fade ${visible ? 'show' : 'hide'}`}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header bg-light p-2 align-items-center text-danger font-weight-bold border-bottom-0">
          <span className="modal-title">
            New Subscription
          </span>
          <button type="button" className="close" onClick={handlers.onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <LoaderBar isVisible={apiResults.pending} />
        <hr className="m-0"/>
        <div className="container mt-2 mb-0">
          <div className="form-group">
            <label>Owner</label>
            <select name="ownerId" className="form-control small pl-2" onChange={handlers.onValueChange}>
              {data.users.map(user => (
                <option key={user._id} value={user._id}>{user.email}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Entity</label>
            <select name="entityId" className="form-control small pl-2" onChange={handlers.onValueChange}>
              {data.entities.map(entity => (
                <option key={entity._id} value={entity._id}>
                  {entity._id ? `${ENTITY_TYPE_MAP[entity.type]} - ${entity.cost}€` : entity.type}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Quantity</label>
            <input
              name="count" className={`form-control ${formErrors['count'] ? 'border-danger' : ''}`}
              maxLength={5} value={formData.count} onChange={handlers.onValueChange}
              placeholder='quantity'
            />
          </div>
          <div className="form-group">
            <label>Cost override</label>
            <input
              name="costOverride" className={`form-control ${formErrors['costOverride'] ? 'border-danger' : ''}`}
              maxLength={8} value={formData.costOverride} onChange={handlers.onValueChange}
              placeholder='cost override'
            />
          </div>
          <div className="form-group">
            <label>VAT %</label>
            <input
              name="vat" className={`form-control ${formErrors['vat'] ? 'border-danger' : ''}`}
              maxLength={8} value={formData.vat} onChange={handlers.onValueChange}
              placeholder='VAT %'
            />
          </div>
          <div className="form-group">
            <label>Active from</label>
            <input
              name="activeFrom" className={`form-control ${formErrors['activeFrom'] ? 'border-danger' : ''}`}
              maxLength={7} value={formData.activeFrom} onChange={handlers.onValueChange}
              placeholder='active from'
            />
          </div>
        </div>
        {apiResults.error ? (
          <div className="bg-danger p-2 my-2 text-white border-top border-bottom" data-notify="container">
            <span data-notify="message">err_not_owner</span>
          </div>
        ) : <hr className="mx-0 my-2"/>}
        <div className="mx-2 text-right">
        <button className="btn btn-warning mt-0" onClick={handlers.onClose}>
            <i className="fa fa-times"></i><span className="ml-3">cancel</span>
          </button>
          <button className="btn btn-info ml-3 mt-0" onClick={handlers.onSave} disabled={!!Object.keys(formErrors).length}>
            <i className="fa fa-check"></i><span className="ml-3">save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
