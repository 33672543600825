import React from 'react';
import { NavLink } from 'react-router-dom';

const Component = () => (
  <div className="sidebar" data-color="default" data-active-color="danger">
    <div className="logo text-danger">
      <div className="ml-2 mb-2 pb-1">Eltrum Systems</div>
    </div>
    <div className="sidebar-wrapper">
      <ul className="nav">
        <li>
          <NavLink className="nav-link" to={`/users`}>
            <i className="nc-icon nc-circle-10"/>
            <p>Users</p>
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to={`/devices`} data-toggle="collapse">
            <i className="nc-icon nc-settings-gear-65"/>
            {/*<p>Devices <b className="caret"/></p>*/}
            <p>Devices</p>
          </NavLink>
          {/*<div className="collapse show">*/}
          {/*  <ul className="nav">*/}
          {/*    <li>*/}
          {/*      <NavLink className="nav-link" to={`/devices/macs`}>*/}
          {/*        <span className="sidebar-mini-icon"><i className="nc-icon nc-sm nc-key-25"/></span>*/}
          {/*        <span className="sidebar-normal">MACs</span>*/}
          {/*      </NavLink>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </li>
        <li>
          <NavLink className="nav-link" to={`/macs`}>
            <i className="nc-icon nc-key-25"/>
            <p>MACs</p>
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to={`/subscriptions`}>
            <i className="fa fa-money"/>
            <p>Subscriptions</p>
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to={`/messages`}>
            <i className="nc-icon nc-chat-33"/>
            <p>Messages</p>
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to={`/firmware`}>
            <i className="nc-icon nc-atom"/>
            <p>Firmware</p>
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to={`/app`}>
            <i className="nc-icon nc-app"/>
            <p>App</p>
          </NavLink>
        </li>
      </ul>
    </div>
  </div>
);

export default Component;
