import axios from 'axios';

// TODO: validate token, etc. (async for token parsing)
const getToken = async (user) => {
  if (!user) {
    throw new Error('User is not authenticated.');
  }
  return user.jwt;
};

const getHeaders = async (user, secure) => ((secure !== false)
  ? ({ Authorization: `Bearer ${await getToken(user)}` })
  : {});

const callApi = async (api, body, user, language = null) => {
  try {
    const { data } = await axios({
      url: api.URL,
      data: ['POST', 'PUT'].includes(api.METHOD) && { ...body, language },
      method: api.METHOD,
      headers: await getHeaders(user, api.secure),
    });
    return ['POST', 'PUT'].includes(api.METHOD) ? { ...body, ...data } : data;
  } catch (error) {
    console.log('----- API ERROR', error, error.response);
    console.clear();
    if (!error.response) {
      return {
        error: true,
        status: 500,
        message: error.message,
      };
    } else {
      // TODO: populate validation error data?
      // TODO: DO NOT spread .response.data here... it might have lots of stuff, not only message...
      return {
        error: true,
        ...error.response,
        message: error.message,
      };
    }
  }
};

export default callApi;
