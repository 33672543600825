import React from 'react';

import Auth from './auth';
import Dashboard from './app';

const View = ({ authenticated, confirmed }) => (
  (authenticated && confirmed) ? <Dashboard /> : <Auth authenticated={authenticated} />
);

export default View;
