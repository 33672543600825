import React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './index.css';
import App from './app';
import * as serviceWorker from './serviceWorker';
import config from './config';

if (!!config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    release: `eltrum-sys-app${process.env.npm_package_version}`,
    integrations: [
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler for finer control
    // tracesSampleRate: 1.0,
    tracesSampleRate: 0.05,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
