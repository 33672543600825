import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';

import View from './view';
import { useApiRequest } from '../../../../../utils/hooks';
import { api as endpoints } from '../../../../../utils/api';
import SubscriptionDetails from './SubscriptionDetails';
import SubscriptionForm from './SubscriptionForm';
import { useModal } from '../../../../components/Modal';

const Component = () => {
  const { state, execute } = useApiRequest();
  useEffect(() => {
    execute(endpoints.subscriptions.loadList());
  } , [execute]);

  const [ data, setData ] = useState([]);
  useEffect(() => {
    setData((isEmpty(state.data) ? [] : state.data).map(entity => ({
      ...entity,
      current: entity.monthly.length ? entity.monthly[entity.monthly.length - 1].total : '-',
    })));
  } , [state.data, setData]);

  const [ sort, setSort ] = useState({ email: '', asc: true });
  const [ filters, setFilters ] = useState({ email: '' });

  const list = data
    .filter(entity => (
      (!filters.email || entity.email.toLowerCase().includes(filters.email.toLowerCase()))
    ))
    .sort((a, b) =>
      (sort.asc && (a[sort.email] < b[sort.email])) || (!sort.asc && (a[sort.email] > b[sort.email])) ? -1 : 1
    );

  const [modalData, setModalData] = useState(null);
  const { Modal: SubscriptionView, onOpen: onOpenSubscription } = useModal(SubscriptionDetails, { data: modalData });
  const { Modal: SubscriptionFormView, onOpen: onOpenSubscriptionCreate } = useModal(SubscriptionForm, {
    handlers: {
      reload: () => {
        execute(endpoints.subscriptions.loadList());
      }
    }
  });

  const handlers = {
    onSortChange: (fieldName) => () => (sort.name === fieldName)
      ? setSort({ ...sort, asc: !sort.asc })
      : setSort({ email: fieldName, asc: true }),
    onFilterChange: (fieldName) => (e) => setFilters({ ...filters, [fieldName]: e.target.value }),
    onOpenSubscription: (data) => () => {
      setModalData(data);
      onOpenSubscription();
    },
    onOpenSubscriptionCreate,
  };

  return <View
    ViewModal={SubscriptionView}
    FormModal={SubscriptionFormView}
    list={list}
    total={data.length}
    sort={sort}
    filters={filters}
    handlers={handlers}
  />;
};

export default Component;
