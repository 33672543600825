export default {
  system: {
    error_500: 'Sistemos klaida. Bandykite vėliau arba kreipkitės į Sistemos Administratorių.',
  },
  auth: {
    btn_login: 'Prisijungti',
    btn_signup: 'Registruotis'
  },
  confirm: {
    title: 'Vartotojo patvirtinimas',
    btn_back: 'Atgal į Registraciją',
    error_403: 'Laikinas raktas neteisingas, arba pasibaigęs galiojimas',
  },
  login: {
    soc: {
      error_400: 'Pasibaigė rakto galiojimas. Bandykite dar kartą.',
      error_401: 'Prisijungimas nepavyko. Bandykite dar kartą.',
      error_403: 'Sistemai reikalingas jūsų pašto adresas, kad galėtų pranešti apie jūsų prietaisų būseną.',
    },
    title: 'Prisijungimas',
    email: 'El. paštas',
    password: 'Slaptažodis',
    login: 'Prisijungti',
    password_forgot: 'Pamiršote slaptažodį?',
    error_401: 'Neteisingi duomenys.',
    error_403: 'Patvirtinkite nurodytą El. pašto adresą.',
  },
  reset_password: {
    title: 'Atkurti slaptažodį',
    email: 'El. paštas',
    success: 'Puiku. Patikrinkite savo el. paštą',
    btn_submit: 'Atkurti',
    error_403: 'Klaida išsiunčiant el. laišką. Susisiekite su sistemos administratoriumi.',
    error_409: 'Klaida išsiunčiant el. laišką. Pabandykite vėliau.',
  },
  reset_confirm: {
    title: 'Slaptažodžio atkūrimas',
    btn_back: 'Atgal į prisijungimą',
    error_403: 'Laikinas raktas neteisingas, arba pasibaigęs galiojimas',
  },
  sign_up: {
    title: 'Registracija',
    email: 'El. paštas',
    btn_submit: 'Registruotis',
    success: 'Puiku. Patikrinkite savo el. paštą',
    error_401: 'Vartotojas egzistuoja. Pasitikrinkite savo el. paštą.',
    error_409: 'Vartotojas egzistuoja. Prisijunkite.',
    error_500: 'Klaida išsiunčiant el. laišką. Pabandykite vėliau.',
  },
  change_password: {
    title: 'Slaptažodžio keitimas',
    message_success: 'Slaptažodis pakeistas.',
    new_password: 'Naujas slaptažodis',
    repeat_password: 'Pakartoti slaptažodį',
    btn_submit: 'Patvirtinti',
    error_401: 'Prieiga negalima.',
  },
  footer: {
    made_with: 'su',
    by: 'Eltrum',
  },
  dashboard_header: {
    change_psw: 'Pakeisti slaptažodį',
    refresh: 'Atnaujinti',
    logout: 'Atsijungti',
  },
  sidebar: {
    devices: 'PRIETAISAI',
    groups: 'GRUPĖS',
  },

  device_add: {
    title: 'Pridėti prietaisą',
    label: 'Ar turite "Eltrum" stotelę?',
    yes_btn: 'Taip',
    no_btn: 'Ne',
  },

  device_assign: {
    title: 'Pridėti prietaisą',
    label: 'Įveskite prietaiso kodą',
    input_lbl: 'Prietaiso kodas',
    btn_submit: 'Patvirtinti',
    error_403: 'Nurodytas neteisingas kodas. Bandykite dar kartą arba kreipkitės į sistemos administratorius.'
  },

  // ---Device registration---
  device_reg: {
    title: 'Prietaiso registracija',

    // inval_stat: 'Įrenginio registracija negalima.',
    // inval_msg: 'Prisijungite prie interneto prieš pradėdami įrenginio registraciją',
    // // ---Networks---
    // conn_ssid: 'Įrenginys prisijungė prie ',
    // select_eltrum: 'Prisijunkite prie WiFi tinklo \'ELTRUM-DEVICE\'. ',
    // info_msg_1: 'Telefono nustatymuose, pasiekiamų WiFi tinklu sąraše raskite \'ELTRUM-DEVICE\' tinklą ir prie jo prisijunkite.',
    // info_msg_2: 'Būkite arti prie įrenginio, kad pasiektumėte jo WiFi prieigos tašką.',
    // info_msg_3: 'Registracijos metu rekomenduojame telefone išjungti mobilius duomenis.',

    step1: 'Susijungimas su Prietaisu',
    step2: 'Prijungimas prie WiFi',
    step3: 'Prijungimas prie Eltrum',

    start: {
      info_lbl1: 'Atidarykite savo mobilaus įrenginio nustatymus.',
      info_lbl2: 'Pasirinkite \'ELTRUM-xxxxxx\' WiFi sąraše.',
      info_lbl3: 'Palaukite kol prietaisas susijungs su jūsų mobiliu įrenginiu.',
    },

    finish: {
      info_lbl1: 'Prietaisas prijungtas prie WiFi - ',
      info_lbl2: 'Prietaisas registruojamas Eltrum Sistemoje. Prašome palaukti.',
    },

    fatal: {
      info_lbl1: 'Nepavyko prisijungti prie Eltrum Sistemos.',
      info_lbl2: 'Perkraukite prietaisą ir pradėkite registraciją nuo pradžių.',
      info_lbl3: 'Susisiekite su Sistemos Administratoriais.',
    },

    offline: {
      info_lbl1: 'Jungiamės prie jūsų prietaiso. Prašome palaukti.',
      info_lbl2: 'Įsitikinkite, kad \'ELTRUM-xxxxxx\' yra pasirinktas WiFi sąraše.',
      info_lbl3: 'Įsitikinkite, kad esate šalia Eltrum prietaiso.',
    },

    connected: {
      info_lbl1: 'Prietaisas prijungtas prie WiFi - ',
      info_lbl2: 'Jungiamės prie jūsų WiFi.',
      info_lbl3: 'Prašome palaukti, arba prisijungti rankiniu būdu.',
    },

    wifi: {
      info_lbl1: 'Gaunamas WiFi sąrašas. Palaukite.',
      info_lbl2: 'Pasirinkite savo WiFi ir įveskite slaptažodį.',
      info_lbl3: 'Prietaisas jungiamas prie nurodyto WiFi. Palaukite.',
      info_lbl4: 'Nepavyko prisijungti prie WiFi.',
      info_lbl5: 'Įsitikinkite, kad pateikėt teisingus duomenis.',
      info_lbl6: 'Prašome bandyti dar kartą.',

      ssid_lbl: 'WiFi pavadinimas',
      password_lbl: 'WiFi slaptažodis',
      connect_btn: 'Prijungti',

      error_400: 'Nepavyko prisijungti prie WiFi. Bandykite dar kartą.',
      error_404: 'Nepavyko prisijungti prie WiFi. Bandykite dar kartą.',
      error_500: 'Nepavyko prisijungti prie WiFi. Bandykite dar kartą.',

      list_loading: 'Gaunamas WiFi sąrašas. Prašome palaukti.',
      select: 'Pasirinkite WiFi',
    }
  },

  root: {
    profile: {
      menu: {
        details_lbl: 'Profilis',
        credentials_lbl: 'Prisijungimas',
      },
      profile_ttl: 'Vartotojas',
      credentials: {
        soc_lbl: 'Su soc. tinklų paskyra',
        soc_small_lbl: 'Pasirinkite ir priskirkite prisijungimo paskyrą',
        fb_lbl: 'facebook',
        google_lbl: 'google',
        pwd_lbl: 'Arba su slaptažodžiu',
        pwd_small_lbl: 'Įveskite naują arba pasikeiskite slaptažodį',
      },
    },
    settings: {
      menu: {
        groups_lbl: 'Grupės',
        languages_lbl: 'Kalba',
        version_lbl: 'Versija',
      },
      settings_ttl: 'Nustatymai',
      show_device_groups_lbl: 'Rodyti grupes',
      groups_lbl: 'Prietaisų grupių nustatymai',
      group_field_placeholder: 'Pavadinimas',
      languages_lbl: 'Pakeisti kalbą',
    },
    messenger: {
      messenger_ttl: 'Pranešimai',
    }
  },

  // ---Device settings---
  settings: {
    menu: {
      group_lbl: 'Grupė',
      share_lbl: 'Dalintis',
      logs_lbl: 'Istorija',
      info_lbl: 'Informacija',
    },

    group: {
      assign_lbl: 'Priskirkite prietaisą grupei',
    },

    share: {
      invite_lbl: 'Pakvieskite kitus naudotis Jūsų prietaisu',
      email_lbl: 'El. paštas',
      max_lbl: 'pasidalinimų daugiausiai.',
      contact_admin_lbl: 'Jei norite daugiau, susisiekite su Sistemos Administratoriais.',

      error_400: 'Nurodytas neteisingas el. paštas.',
      error_401: 'Prieiga neleistina.',
      error_403: 'Pasiektas maksimalus pasidalinimo skaičius. Susisiekite su Sistemos Administratoriais.',
    },

    logs: {
      logs_lbl: 'Prietaiso istorija',
      empty_lbl: 'Įrašų nėra.'
    },

    info: {
      type_lbl: 'Tipas:',
      mac_lbl: 'MAC:',
      upgrade_warning: 'Prietaiso programinė įranga bus atnaujinta. ' +
        'Visi buvę nustatymai bus ištrinti ir prietaisas persikraus. ' +
        'Operacija užtruks kelias minutes. ' +
        'Ar tikrai norite atnaujinti savo prietaiso programinę įrangą?',
      remove_warning: 'Visi išsaugoti prietaiso duomenys bus ištrinti. ' +
        'Visi prietaiso nustatymai bus ištrinti. ' +
        'Prietaise bus atstatyti gamykliniai parametrai. ' +
        'Prietaisas bus ištrintas ir perkrautas. ' +
        'Ar tikrai norite ištrinti savo prietaisą?',
    },

    settings: {
      upgrade_btn: 'Atnaujinti',
      remove_btn: 'Ištrinti',
      yes_btn: 'Taip',
      no_btn: 'Ne',
    },

    reports: {
      name_lbl: 'Pavadinimas',
    },
  },

  types: {
    device: 'Prietaisas',
    lock: 'Spyna',
    thermo: 'Termometras',
    gates: 'Vartai',
    switch: 'Jungtukas',
  },

  devices: {
    device: {
      name_lbl: 'Pavadinimas',
    },
    gates: {
      manual_lbl: 'rankinis',
      auto_lbl: 'auto',
      auto_settings_lbl: 'auto nustatymai',
      auto_time_lbl: 'minutės',
    },
    lock: {
      key_owner_name_lbl: 'Rakto savininko vardas',
    },
    switch: {
      on: 'Įjungtas',
      off: 'Išjungtas',
    },
    thermo: {
      sensor_list_lbl: 'Jutikliai',
      temp_raw_lbl: 'Temperatūros indikatoriai',
      temp_diff_lbl: 'Temperatūrų skirtumo indikatoriai',
      warning_from_lbl: 'Įspėjimas nuo',
      danger_from_lbl: 'Pavojus nuo',
      save_config_btn: 'Išsaugoti',
      remove_device_btn: 'Ištrinti prietaisą',
      upgrade_device_btn: 'Atnaujinti prietaisą',
      yes_btn: 'Taip',
      no_btn: 'Ne',
      min_lbl: 'min temp.',
      avg_lbl: 'vid temp.',
      max_lbl: 'max temp.',
      diff_lbl: 'max pokytis',
    },
    thermo_x4: {
      min_lbl: 'min',
      max_lbl: 'max',
      avg_lbl: 'vid',
    }
  },

  profile: {
    info: {
      title: 'Profilis',
    }
  },

  modal: {
    install: {
      title: 'Diegimas',
      lbl_do_not_show_again: 'Neberodyti',
      btn_close: 'Uždaryti',
    }
  },
}

