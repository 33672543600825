import reducer from '../utils/reducer';
import { devices } from '../modules';

const defaultGroups = {
  1: '1',
  2: '2',
  3: '3',
};

const initialState = {
  visible: false,
  names: defaultGroups,
  data: {
    selectedId: 1,
  },
};

const handlers = {
  [devices.groups.load.TYPES.SUCCESS]: (state, payload) => ({
    ...state,
    names: payload ? {
      1: payload[1],
      2: payload[2],
      3: payload[3],
    } : defaultGroups,
  }),
  [devices.groups.changeName.TYPE]: (state, payload) => ({
    ...state,
    names: { ...state.names, [payload.idx]: payload.name },
  }),
  [devices.groups.setVisible.TYPE]: (state, payload) => ({
    ...state,
    visible: payload,
  }),
  [devices.groups.setActiveGroup.TYPE]: (state, payload) => ({
    ...state,
    data: { selectedId: payload },
  }),
};

export default reducer(handlers, initialState);
