const ACTION_TYPE = 'API';

const createActionTypes = namespace => ({
  REQUEST: `${namespace}_REQUEST`,
  SUCCESS: `${namespace}_SUCCESS`,
  FAILURE: `${namespace}_FAILURE`,
});

const getHandlers = (namespace, handlers) => {
  const actionTypes = createActionTypes(namespace);
  const defaultActions = ({
    onRequest: () => dispatch => dispatch({ type: actionTypes.REQUEST }),
    onSuccess: data => dispatch => dispatch({ type: actionTypes.SUCCESS, payload: data }),
    onFailure: error => dispatch => {
      if (!error.response) {
        console.log('___APP_ERROR:', error.message);
        return dispatch({ type: actionTypes.FAILURE, payload: { status: 500, message: error.message } });
      }

      console.log('___API_ERROR:', error.response);
      const payload = (error.response.status === 500)
        ? { ...error.response, message: 'System Error. Contact administrator.' }
        : { ...error.response, ...error.response.data };
      dispatch({ type: actionTypes.FAILURE, payload: { status: payload.status, message: payload.message } });
    },
  });
  return {
    onRequest: handlers.onRequest || defaultActions.onRequest,
    onSuccess: handlers.onSuccess || defaultActions.onSuccess,
    onFailure: handlers.onFailure || defaultActions.onFailure,
  };
};

const actions = {
  /**
   * @param namespace - action namespace, e.g. 'USER_LOGIN'.
   * @param endpoint - API endpoint: { URL, METHOD, secure },
   * where secure = false for unauthenticated endpoints.
   * @param body - request body required for API methods (POST, PUT mostly).
   * @param handlers - api request handlers: { onRequest: () => {}, onSuccess = (result) => {}, onFailure = (error) => {} }.
   * Default handlers will be created for unspecified ones.
   * Default handlers will dispatch namespaced action (_REQUEST, _SUCCESS, _FAILURE).
   * @returns {{payload: {data: *, handlers: *, namespace: *, api: *}, type: *}} -
   * an actual action for API call.
   */
  callAPI: (namespace, endpoint, body, handlers) =>
    ({ type: ACTION_TYPE, payload: { api: endpoint, body, namespace, handlers } }),
};

export { ACTION_TYPE, actions, createActionTypes, getHandlers };
