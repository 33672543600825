import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import View from './view';
import joi from 'joi';
import { useApiRequest } from '../../../../../../utils/hooks';
import { api as endpoints } from '../../../../../../utils/api';
import moment from 'moment';
import { useModalApi, useModalForm } from '../../../../../components/Modal';

const formSchema = joi.object({
  ownerId: joi.string().required(),
  entityId: joi.string().required(),
  count: joi.number().required(),
  costOverride: joi.number().optional().allow('', null),
  vat: joi.number().optional().allow('', null),
  activeFrom: joi.date().required(),
}).options({ abortEarly: false, stripUnknown: true });

const defaultFormState = {
  ownerId: '',
  entityId: '',
  count: 1,
  costOverride: '',
  vat: 21,
  activeFrom: moment().format('YYYY-MM'),
};

const useFetchApiData = (endpoint) => {
  const { state, execute } = useApiRequest();
  useEffect(() => {
    execute(endpoint);
  } , [execute, endpoint]);

  const [ data, setData ] = useState([]);
  useEffect(() => {
    if (state.success) {
      setData(state.data);
    }
  } , [state.data, state.success, setData]);

  return { state, data };
}

const Component = ({ visible, handlers: parentHandlers }) => {
  const { t } = useTranslation();

  const usersEndpoint = useMemo(() => endpoints.users.loadList(), []);
  const { data: users } = useFetchApiData(usersEndpoint);

  const entityEndpoint = useMemo(() => endpoints.subscriptionEntities.loadList(), []);
  const { data: entities } = useFetchApiData(entityEndpoint);

  const formattedUsers = useMemo(() =>
    users.sort((a, b) => a.email.localeCompare(b.email)), [users]);

  const formattedEntities = useMemo(() =>
    Object.keys(entities).map((key) => entities[key]), [entities]);

  const data = {
    users: [{ _id: '', email: '------------------' }, ...formattedUsers],
    entities: [{ _id: '', type: '------------------' }, ...formattedEntities],
  }

  const { data: formData, setData: setFormData, formErrors } =
    useModalForm(formSchema, defaultFormState, visible);
  const { apiResults, callModalApi } = useModalApi(parentHandlers);

  const handlers = {
    ...parentHandlers,
    onValueChange: (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    onSave: async () => {
      if (!Object.keys(formErrors).length) {
        await callModalApi(endpoints.subscriptions.create(), formData);
        parentHandlers.reload();
      }
    },
  };

  return <View
    visible={visible}
    data={data}
    formData={formData}
    formErrors={formErrors}
    apiResults={apiResults}
    handlers={handlers}
    t={t}
  />;
};

export default Component;
